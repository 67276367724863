/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */ 

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import appleTouchIcon from "../../static/favicons/apple-touch-icon.png?v=1.1"
import icon32 from "../../static/favicons/favicon-32x32.png?v=1.1"
import icon16 from "../../static/favicons/favicon-16x16.png?v=1.1"
import safariPinnedTab from "../../static/favicons/safari-pinned-tab.svg?v=1.1"
import defaultCoverLogo from "../../static/favicons/landshaft-logo-1200-900.jpg?v=1.1"

function CriticalHead ({ description, lang = "ru-RU", meta, title , coverLogo = defaultCoverLogo}) {
  const { pathname } = useLocation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            
          }
        }
      }
    `
  )
  
  let metaDescription = site.siteMetadata.description;

  if (description) {
    metaDescription = description;
  }

  const defaultTitle = site.siteMetadata?.title
  let baseURL = site.siteMetadata.siteUrl;
  let currentURL = `${baseURL}${pathname}`


  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix: "og: https://ogp.me/ns#"
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | ${title}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        // {
        //   name: `robots`,
        //   content: "noindex",
        // },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:locale`,
          content: "ru_RU",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: currentURL,
        },
        {
          property: `og:image`,
          content: coverLogo, 
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`, 
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`, 
        },
        {
          property: `og:image:width`,
          content: `1200`, 
        },
        {
          property: `og:image:height`,
          content: `900`, 
        },
        {
          property: `og:image:alt`,
          content: `Элитная недвижимость в коттеджном посёлке «Ландшафт» на Рублёво-Успенском шоссе.`, 
        },
        {
          property: `twitter:image`,
          content: coverLogo,
        },

        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata?.author || ``,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `format-detection`,
          content: `telephone=no`,
        },
        {
          name: `msapplication-TileColor`,
          content: `#fbfbfb`,
        },
        {
          name: `theme-color`,
          content: `#fbfbfb`,
        },
        {
          name: `yandex-verification`,
          content: `f1ce0c4102a78432`, // yandex webmaster verification for `landshaft.estate` domain
        },
        {
          name: `yandex-verification`,
          content: `1ad5fe382acea4a8`, // yandex webmaster verification for `кпландшафт.рф` domain
        },
      ].concat(meta)}
      link={[
        { rel: 'apple-touch-icon', sizes: '180x180', href: `${appleTouchIcon}` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${icon32}` },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${icon16}` },
        { rel: 'mask-icon', color: '#578270', href: `${safariPinnedTab}` },
        { rel: 'canonical', href: currentURL },
      ]}
    >
      <script>
      {`
        let defaultFontSize = 16;
        let vw = window.innerWidth / 100;
        let fluidFontSize = 16 * 0.8 + vw * 0.6;
        let clampedFontSize = fluidFontSize < defaultFontSize ? defaultFontSize : fluidFontSize > defaultFontSize * 3.25 ? defaultFontSize * 3.25 : fluidFontSize;
        let flooredFontSize = parseInt(clampedFontSize) + "px";
        document.documentElement.style.setProperty('--base-font-size', flooredFontSize);
      `}
      </script>
    </Helmet>
  )
}

CriticalHead.defaultProps = {
  lang: `ru`,
  meta: [],
  description: ``,
}

CriticalHead.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default CriticalHead
