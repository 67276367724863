/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useRef, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { throttle, getAndSetCustomVh} from "../helpers/littleHelpers"

import { YMaps } from 'react-yandex-maps';

import "../styles/main.scss"
import { MainContext } from '../helpers/MainContext'


const Layout = ({ children }) => {
  const [mainFormVisibility, setMainFormVisibility] = useState(false)
  const [contactsVisibility, setContactsVisibility] = useState(false)
  const [locationVisibility, setLocationVisibility] = useState(false)
  const [objectFormVisibility, setObjectFormVisibility] = useState(false)
  const [objectRentFormVisibility, setObjectRentFormVisibility] = useState(false)
  const [objectPresentationVisibility, setObjectPresentationVisibility] = useState(false)
  const [filterObjectsBy, setFilterObjectsBy] = useState("onSale")
  const [rentObjectOfInterest, setRentObjectOfInterest] = useState({objectID: undefined, name: undefined})
  const [secretOfInterest, setSecretOfInterest] = useState({objectID: undefined, name: undefined})
  const firstRender = useRef(true);
  
  useEffect(() => {
    if (firstRender.current === true) {
      let hasAccuratePointerDevice = window.matchMedia("pointer: fine");

      if (hasAccuratePointerDevice.matches === true) {
        getAndSetCustomVh();
        window.addEventListener('resize', throttle(getAndSetCustomVh, 1000));
      }

      firstRender.current = false;
    }
  }, [])

  const value = {
    mainFormVisibility,
    setMainFormVisibility,
    contactsVisibility,
    setContactsVisibility,
    locationVisibility,
    setLocationVisibility,
    objectFormVisibility,
    setObjectFormVisibility,
    objectRentFormVisibility,
    setObjectRentFormVisibility,
    objectPresentationVisibility,
    setObjectPresentationVisibility,
    filterObjectsBy,
    setFilterObjectsBy,
    rentObjectOfInterest,
    setRentObjectOfInterest,
    secretOfInterest,
    setSecretOfInterest
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MainContext.Provider value={value}>
      {/* <YMaps preload query={{ */}
      <YMaps query={{
         apikey: "df4561c2-7367-407f-9c07-122bd424d29e",
         }} >
        <div id="body-like-wrapper">
          <main>{children}</main>
          <footer id="footer">
            <div>
            <p id="legalName">ООО «ЛАНДШАФТ»</p>
            <p id="legalAddress">143082, Московская область, город Одинцово, деревня Жуковка, территория Ландшафт, строение 110</p>
            </div>
            <p id="legalOffer">Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ</p>
            <p id="madeIn">
              © {new Date().getFullYear()}, сделано с
                <span className="emoji">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" aria-label="любовью">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                </svg>
                </span>
              в Ландшафте
            </p>
          </footer>
        </div>
      </YMaps>
    </MainContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
