import {createContext} from "react"

export const MainContext = createContext({
    mainFormIsVisible: true,
    changeMainFormVisibility: () => {},
    ContactsIsVisible: true,
    changeContactsVisibility: () => {},
    LocationIsVisible: true,
    changeLocationVisibility: () => {},
    objectFormIsVisible: true,
    changeObjectFormVisibility: () => {},
    objectRentFormIsVisible: true,
    changeObjectRentFormVisibility: () => {},
    objectPresentationIsVisible: true,
    changeObjectPresentationVisibility: () => {},
    objectsFilter: "onSale",
    changeObjectsFilter: () => {},
    rentObjectOfInterest: {objectID: undefined, name: undefined},
    changeRentObjectOfInterest: () => {},
    secretOfInterest: {objectID: undefined, name: undefined},
    changeSecretOfInterest: () => {},

  });