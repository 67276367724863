import React from "react";

export function areaMeasureNaming(n) {
    if ((/([^1]1$)|^1$/).test(n)) {
        return "сотка";
    } if ((/^[2,3,4](\.\d*)?$|[^1][2,3,4](\.\d*)?$/).test(n)) {
        return "сотки";
    } else {
        return "соток"
    }
}

export const spacefyNumber = ( n, FractionDigits = 2 ) => {
    let newNumber = n.toLocaleString(undefined, {maximumFractionDigits: FractionDigits});
    return newNumber;
}

export const getAllSiblings = (elem, filter) => {
    const sibs = [];
    elem = elem.parentNode.firstChild;
    do {
        if (elem.nodeType === 3) continue;
        if (!filter || filter(elem)) sibs.push(elem);
    } while (elem = elem.nextSibling) //gets a warning that seems mistaken
    return sibs;
}

export const throttle = (fn, delay) => {
    let lastCalled = 0;
    return (...args) => {
      let now = new Date().getTime();
      if(now - lastCalled < delay) {
        return;
      }
      lastCalled = now;
      return fn(...args);
    }
  }

export async function fetchWithTimeout(resource, options) {
    const { timeout = 8000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
  
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);
  
    return response;
}

// handle stale state with custom hook, thx to: https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/
export function useAsyncReference(value, isProp = false) {
    const ref = React.useRef(value);
    const [, forceRender] = React.useState(false);
  
    function updateState(newState) {
      ref.current = newState;
      forceRender(s => !s);
    }

    if (isProp) {
      ref.current = value;
      return ref;
    }
    
    return [ref, updateState];
  }

// fighting sick address bars in mobile browsers with setting custom vh unit:
export const getAndSetCustomVh = () => {
  
  window.setTimeout(() => {
    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
  }, 160)

}

//get device pixel density, based on: https://stackoverflow.com/questions/16541676/what-are-best-practices-for-detecting-pixel-ratio-density
//max output - 3
export const getDevicePixelRatio = () => {
  let mediaQuery;
  let is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (window.devicePixelRatio !== undefined && !is_firefox) {
      if (window.devicePixelRatio > 2 ) {
        return 2;
      } else {
        return window.devicePixelRatio;
      }
  } else if (window.matchMedia) {
      mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";
      if (window.matchMedia(mediaQuery).matches) {
          return 1.5;
      }
      mediaQuery = "(-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx)";
      if (window.matchMedia(mediaQuery).matches) {
          return 2;
      }
  } else {
      return 1;
  }
}

// for image galleries to force next and previous slides to load eagerly
// function to be called "onSlideChange" in swiper instance
export const eagerNeightborsLoading = (swiper) => {
  const length = swiper.slides.length;
  let nextSlide, prevSlide, nextImage, prevImage;
  
  if (swiper.slides.length < 1) {
    return
  } 

  if (swiper.realIndex === length - 1) {
      nextSlide = swiper.slides[0]
      prevSlide = swiper.slides[swiper.realIndex - 1 + 2];
  } if (swiper.realIndex === 0) {
      nextSlide = swiper.slides[1];
      prevSlide = swiper.slides[length - 1];
  } else {
      nextSlide = swiper.slides[swiper.realIndex + 1 + 1];
      prevSlide = swiper.slides[swiper.realIndex - 1 + 1];
  }
  
  nextImage = Array.from(nextSlide.children[0].children).find(img => !img.getAttribute("data-lqip"))
  prevImage = Array.from(prevSlide.children[0].children).find(img => !img.getAttribute("data-lqip"))
  
  nextImage.setAttribute("loading", "eager")
  prevImage.setAttribute("loading", "eager")
  
}